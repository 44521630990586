<template>
    <div style="height: 100%">
        <!-- slot: map -->
        <slot name="map">
<!--            <div class="bg-info-light"> panel map</div>-->
        </slot>
    </div>
</template>

<script>

export default {
    name: "PanelMap",
    components: {},
    props: {},
    data() {
        return {}
    },
    mounted() {
    },
    methods: {},
    computed: {},
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
