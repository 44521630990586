<template>
    <div class="border panel-adventure"
         :style="getTemplateStyle" style="height: 100%"
    >
        <!-- title -->
        <div v-resize @resize="layoutResize($event, 'title')">
            <slot name="title">
                <div v-if="titleVisible" class="title px-1 bg-secondary d-flex justify-content-between">
                    <div>
                        {{title}}
                    </div>
                    <!-- button refresh -->
                    <b-button
                        v-if="buttonRefreshVisible"
                        size="sm"
                        variant="primary"
                        @click="$emit('refresh')"
                    >
                        <b-icon icon="arrow-repeat" scale="1.5" variant="light"></b-icon>
                    </b-button>
                </div>
            </slot>
        </div>
        <!-- toolbar -->
        <div v-resize @resize="layoutResize($event, 'toolbar')" class="px-1">
            <slot name="toolbar">
                <!--                <b-button-toolbar class="button-toolbar py-1 d-flex">-->
                <!--                    &lt;!&ndash; button new &ndash;&gt;-->
                <!--                    <b-button size="sm" class="">-->
                <!--                        <b-icon-plus-->
                <!--                            scale="1.8"-->
                <!--                            v-b-tooltip.topright.hover.v-info.html="$t('form.save')"-->
                <!--                            @click.stop="$emit('eventSave')"-->
                <!--                        />-->
                <!--                        </b-button>-->
                <!--                    &lt;!&ndash; button edit &ndash;&gt;-->
                <!--                    <b-button size="sm" class="ml-1">-->
                <!--                        <b-icon-file-text-->
                <!--                            scale="1.2"-->
                <!--                            v-b-tooltip.topright.hover.v-info.html="$t('form.edit')"-->
                <!--                            @click.stop="$emit('eventSave')"-->
                <!--                        />-->
                <!--                    </b-button>-->
                <!--                    &lt;!&ndash; delimiter &ndash;&gt;-->
                <!--                    <div class="mx-2"></div>-->
                <!--                    &lt;!&ndash; button save &ndash;&gt;-->
                <!--                    <b-button size="sm" class="ml-1">-->
                <!--                        <b-icon-save-->
                <!--                            scale="1.2"-->
                <!--                            v-b-tooltip.topright.hover.v-info.html="$t('form.save')"-->
                <!--                            @click.stop="$emit('eventSave')"-->
                <!--                        />-->
                <!--                        </b-button>-->
                <!--                    &lt;!&ndash; button publish &ndash;&gt;-->
                <!--                    <b-button size="sm" class="ml-1">-->
                <!--                        <b-icon-check-->
                <!--                            scale="1.8"-->
                <!--                            v-b-tooltip.topright.hover.v-info.html="$t('form.publish')"-->
                <!--                            @click.stop="$emit('eventPublish')"-->
                <!--                        />-->
                <!--                        </b-button>-->
                <!--                </b-button-toolbar>-->
            </slot>
        </div>

        <!-- label: body-0 -->
        <!--        <div v-resize @resize="layoutResize($event, 'label-body-0')">-->
        <!--            <div class="label px-1 my-2 bg-primary-light d-flex">-->
        <!--                <div class="flex-grow-1">-->
        <!--                    {{$t('form.info')}}-->
        <!--                </div>-->
        <!--                &lt;!&ndash; button collapse/expand &ndash;&gt;-->
        <!--                <b-icon-->
        <!--                    :icon="true ? 'three-dots-vertical' : 'three-dots'"-->
        <!--                    style="cursor: pointer;"-->
        <!--                    scale="0.9"-->
        <!--                />-->
        <!--            </div>-->
        <!--        </div>-->

        <!-- body 0 -->
        <div v-resize @resize="layoutResize($event, 'body-0')">
            <slot name="body-0"/>
        </div>

        <!-- label: body-1 -->
        <div v-if="!__isNull(label1Text)" v-resize @resize="layoutResize($event, 'label-body-1')">
            <div class="label px-1 my-2 bg-primary-light d-flex">
                <div class="flex-grow-1">
                    {{label1Text}}
                </div>
                <!-- button collapse/expand -->
                <!--                <b-icon-->
                <!--                    :icon="true ? 'three-dots-vertical' : 'three-dots'"-->
                <!--                    style="cursor: pointer;"-->
                <!--                    scale="0.9"-->
                <!--                />-->
            </div>
        </div>

        <!-- body 1 -->
        <draggable
            ref="refBody_1"
            :list="list"
            :disabled="draggableOff"
            class="body p-1"
            group="panelAdventureGroup"
            ghost-class="draggable-ghost"
            :style="{'max-height': getBodyMaxHeight.value <= 0 ? null : getBodyMaxHeight.px}"
        >
            <slot name="draggable-body-1">
                <div v-if="placeHolderVisible" class="flex-grow-1 d-flex justify-content-center align-items-center"
                     style="color: darkgray"
                     :style="{'height': getBodyMaxHeight.value <= 0 ? null : getBodyMaxHeight.value-8 + 'px'}"
                >
                    {{__isNull(placeholderText) ? $t('form.no_data') : placeholderText}}
                </div>
            </slot>
        </draggable>
        <!-- body 2 -->
        <div v-resize @resize="layoutResize($event, 'body-2')">
            <slot name="body-2"/>
        </div>
        <!-- footer -->
        <div v-resize @resize="layoutResize($event, 'footer')">
            <slot name="footer">
                <!--                <div class="mt-1 p-1">-->
                <!--                    <b-button>aaa</b-button>-->
                <!--                    <b-button>b</b-button>-->
                <!--                </div>-->
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "PanelAdventure",
    components: {},
    props: {
        titleVisible: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: "Header title"
        },
        buttonRefreshVisible: {
            type: Boolean,
            default: true
        },
        fixedWidth: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '200px'
        },
        maxHeight: {
            type: Number,
            default: 0
        },
        list: {
            type: Array,
            default() {
                return [];
            }
        },
        label1Text: {
            type: String,
            default: "Label 1"
        },
        placeHolderVisible: {
            type: Boolean,
            default: false
        },
        placeholderText: {
            type: String,
            default: null
        },
        draggableOff: {
            type: Boolean,
            default: false
        },
        // Component index to scroll
        scrollIndex: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            layout: [],
        }
    },
    mounted() {
    },
    methods: {
        // <-- layout -->
        layoutResize(e, componentName) {
            let layout = this.__layoutAdd(this.layout, componentName, e.detail.width, e.detail.height);
        },
    },
    computed: {
        getBodyMaxHeight() {
            let value = this.maxHeight - this.__layoutHeightCalculate(this.layout);
            value -= 10;
            return {
                value: value,
                px: value + 'px'
            };
        },
        getTemplateStyle() {
            // <-- unfixed width -->
            let style = {
                'min-width': this.width
            }
            // <-- fixed width -->
            if (this.fixedWidth) {
                style = {
                    'width': this.width
                }
            }
            // <--  -->
            return style;
        },
    },
    filters: {},
    watch: {
        'scrollIndex': {
            handler: function (index) {
                if (this.__isNull(index)) {
                    return;
                }
                // <--  -->
                let scroll_y = this.$refs.refBody_1.$el.children[index].offsetTop;
                scroll_y -= 85;
                this.$refs.refBody_1.$el.scrollTo(0, scroll_y);
            },
            deep: true
        },
    }
}
</script>

<style scoped>
.panel-adventure {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title {
    height: 50px;
    display: flex;
    justify-content: start;
    align-items: center;
    color: white;
}

.body {
    flex: 1 0 auto;
    overflow: auto;
}

.label {
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 5px 5px 0 0;
}
</style>
