<template>
    <div class="main"
         v-show="!getDestination.reloading"
         v-resize @resize="layoutResize($event, 'main', 'main')"
    >
        <!-- left -->
        <div class="left py-2 overflow-auto">
            <!-- destinations button -->
            <b-button
                class="mx-2"
                :variant="hotlink.experienceId === null ? 'secondary' : 'primary'"
                size="sm"
                @click="hotlink.experienceId = null"
            >
                {{$tc('adventure.destination.main', 0)}}
            </b-button>
            <!-- delimiter -->
            <div v-if="getExperiences.length > 0"
                 class="d-flex justify-content-center align-items-center text-secondary">. . . . .
            </div>
            <!-- experiences buttons -->
            <b-button
                v-for="experience in getExperiences"
                class="mt-1 mx-2"
                :variant="hotlink.experienceId === experience.record.id ? 'secondary' : 'primary'"
                size="sm"
                @click="hotlink.experienceId = experience.record.id"
            >
                {{experience.title}}
            </b-button>
        </div>
        <!-- right -->
        <div class="overflow-auto right"
             v-resize @resize="layoutResize($event, 'right', 'main')"
             :style="{'height': getRightHeight.px}">
            <!-- destination -->
            <div v-show="hotlink.experienceId === null" class="px-1">
                <!-- name, subtitle -->
                <div class="pt-1" v-resize @resize="layoutResize($event, 'title', 'destination', 4)">
                    <h2 class="d-inline">
                        {{getDestination.name}}
                    </h2>
                    <!-- subtitle -->
                    <h5 class="d-inline text-secondary">
                        {{getDestination.subtitle}}
                    </h5>
                </div>
                <!-- reviews -->
<!--                <div class="d-inline"
                     v-resize @resize="layoutResize($event, 'reviews', 'destination', 1)">
                    <b-icon-star-fill variant="danger"></b-icon-star-fill>
                    <b-icon-star-fill variant="danger"></b-icon-star-fill>
                    <b-icon-star-fill variant="danger"></b-icon-star-fill>
                    <b-icon-star-fill variant="danger"></b-icon-star-fill>
                    <b-icon-star-fill variant="danger"></b-icon-star-fill>
                    <div class="d-inline ml-1">(4 {{$t('adventure.destination.tab.reviews').toLowerCase()}})</div>
                </div>-->
                <!-- languages -->
                <div v-show="!__isNull(getDestination.languages)" class="pt-1" v-resize
                     @resize="layoutResize($event, 'languages', 'destination')">
                    <div class="text-secondary font-weight-bold d-inline">{{$tc('form.language', 0)}}:</div>
                    <div class="d-inline ml-1">{{getDestination.languages}}</div>
                </div>
                <!-- multi pictures -->
                <div v-if="!__isNull(getDestinationPictures)" class="d-flex py-1" v-resize
                     @resize="layoutResize($event, 'pictures', 'destination')">
                    <!-- previous -->
                    <b-button :disabled="carouselFirst === 0" class="button-no-bg p-0" @click="carouselPrevious()"
                              style="width: 20px">
                        <b-icon
                            icon="chevron-left"
                            :scale="0.8"
                            :variant="carouselFirst === 0 ? 'primary' : 'secondary'"
                        />
                    </b-button>
                    <!-- images multi carousel -->
                    <div draggable="false" class="flex-grow-1 d-flex justify-content-center align-items-center">
                        <b-img draggable="false" aria-selected="false" class="destination-img"
                               v-for="(picture, index) in getDestinationPictures"
                               :src="picture.image.src_p"
                               style="max-width: 85px; max-height: 60px"
                        >
                        </b-img>
                    </div>
                    <!-- next -->
                    <b-button :disabled="carouselDisabled" class="button-no-bg p-0" @click="carouselNext()"
                              style="width: 20px">
                        <b-icon
                            icon="chevron-right"
                            :scale="0.8"
                            :variant="carouselDisabled ? 'primary' : 'secondary'"
                        />
                    </b-button>
                </div>
                <!-- buttons tab -->
                <div class="border">
                    <!-- buttons -->
                    <div class="d-flex flex-row flex-wrap bg-primary py-1 pl-1"
                         v-resize @resize="layoutResize($event, 'tabButtons', 'destination')"
                    >
                        <!-- description -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonDActive==='description' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonDActive='description'">
                            <b-icon
                                :icon="!__isNull(getDestination.description) ? 'b-icon-house-fill' : 'b-icon-house'"/>
                            {{$t('adventure.destination.tab.description')}}
                        </b-button>
                        <!-- host -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonDActive==='host' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonDActive='host'">
                            <b-icon
                                :icon="!__isNull(getDestination.host) ? 'b-icon-person-fill' : 'b-icon-person'"/>
                            {{$t('adventure.destination.tab.host')}}
                        </b-button>
                        <!-- address -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonDActive==='address' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonDActive='address'">
                            <b-icon
                                :icon="!__isNull(getDestination.address) ? 'b-icon-geo-alt-fill' : 'b-icon-geo-alt'"/>
                            {{$t('adventure.destination.tab.address')}}
                        </b-button>
                        <!-- contact -->
                        <!--                        <b-button size="sm" style="font-size: smaller"
                                                          :class="buttonDActive==='contact' ? 'button-tab-active' : 'button-tab'"
                                                          @click="buttonDActive='contact'">
                                                    <b-icon
                                                        :icon="!__isNull(getDestination.contact) ? 'b-icon-envelope-fill' : 'b-icon-envelope'"/>
                                                    {{$t('adventure.destination.tab.contact')}}
                                                </b-button>-->
                        <!-- reviews -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonDActive==='reviews' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonDActive='reviews'">
                            <b-icon variant="danger"
                                    :icon="!__isNull(getDestination.reviews) ? 'b-icon-star-fill' : 'b-icon-star'"></b-icon>
                            {{$t('adventure.destination.tab.reviews')}}
                        </b-button>
                        <!-- notes -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonDActive==='notes' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonDActive='notes'">
                            <b-icon variant="info"
                                    :icon="!__isNull(getDestination.notes) ? 'b-icon-file-earmark-text-fill' : 'b-icon-file-earmark-text'"></b-icon>
                            {{$t('adventure.destination.tab.notes')}}
                        </b-button>
                    </div>
                    <!-- bodies  -->
                    <!-- body description -->
                    <div v-show="buttonDActive==='description'"
                         class="overflow-auto p-1" style="height: inherit"
                         :style="{'height': getTabDestinationBodyHeight.px}"
                         v-html="getDestination.description"
                    />
                    <!-- body host -->
                    <div v-show="buttonDActive==='host'"
                         class="overflow-auto p-1" style="height: inherit"
                         :style="{'height': getTabDestinationBodyHeight.px}"
                         v-html="getDestination.host"
                    />
                    <!-- body address -->
                    <div v-show="buttonDActive==='address'"
                         class="overflow-auto p-1" style="height: inherit"
                         :style="{'height': getTabDestinationBodyHeight.px}"
                         v-html="getDestination.address"
                    />
                    <!-- body contact -->
                    <!--                    <div v-show="buttonDActive==='contact'"
                                             class="overflow-auto p-1" style="height: inherit"
                                             :style="{'height': getTabDestinationBodyHeight.px}"
                                             v-html="getDestination.contact"
                                        />-->
                    <!-- body reviews -->
                    <div v-show="buttonDActive==='reviews'"
                         class="overflow-auto p-1" style="height: inherit"
                         :style="{'height': getTabDestinationBodyHeight.px}"
                         v-html="getDestination.reviews"
                    />
                    <!-- body notes -->
                    <div v-show="buttonDActive==='notes'" class="overflow-auto p-1" style="height: inherit"
                         :style="{'height': getTabDestinationBodyHeight.px}"
                         v-html="getDestination.notes"
                    />
                </div>
            </div>
            <!-- experience -->
            <div v-show="hotlink.experienceId != null">
                <!-- content, picture -->
                <div class="d-flex flex-row justify-content-between overflow-auto mb-1"
                     v-resize @resize="layoutResize($event, 'contentPicture', 'experience')"
                     style="height: 160px"
                >
                    <!-- content -->
                    <div class="flex-grow-1 px-1">
                        <!-- name, subtitle -->
                        <div class="pt-1">
                            <h2 class="d-inline">
                                {{getExperience.name}}
                            </h2>
                            <!-- subtitle -->
                            <h5 class="d-inline text-secondary">
                                {{getExperience.subtitle}}
                            </h5>
                        </div>
                        <!-- reviews -->
<!--                        <div class="d-inline"
                             v-resize @resize="layoutResize($event, 'reviews', 'destination', 1)">
                            <b-icon-star-fill variant="danger"></b-icon-star-fill>
                            <b-icon-star-fill variant="danger"></b-icon-star-fill>
                            <b-icon-star-fill variant="danger"></b-icon-star-fill>
                            <b-icon-star-fill variant="danger"></b-icon-star-fill>
                            <b-icon-star-fill variant="danger"></b-icon-star-fill>
                            <div class="d-inline ml-1">(5 {{$t('adventure.destination.tab.reviews').toLowerCase()}})
                            </div>
                        </div>-->
                        <!-- participant number, duration -->
                        <div class="d-flex flex-row justify-content-between pt-1 pr-1">
                            <!-- participant number -->
                            <div>
                                <b class="text-secondary">{{$t('adventure.participants_number')}}:</b>
                                {{getExperience.participantNumber}}
                            </div>
                            <!-- duration -->
                            <div>
                                <b class="text-secondary">{{$t('adventure.duration')}}:</b>
                                {{getExperience.duration}}
                            </div>
                        </div>
                        <!-- categories/types -->
                        <b-card-text class="text-primary m-0" style="clear: both">
                                    <span v-for="categoryTypes in getExperience.categoriesTypes">
                                        <!-- categories -->
                                        <b-badge variant="secondary">
                                            {{categoryTypes.category}}
                                        </b-badge>
                                        <!-- types -->
                                        <b-badge v-for="(type, index) in categoryTypes.types" variant="primary"
                                                 :class="index === categoryTypes.types.length-1 ? 'ml-1 mr-2' : 'ml-1'"
                                        >
                                            {{type}}
                                        </b-badge>
                                    </span>
                        </b-card-text>
                        <!-- seasons -->
                        <b-card-text class="pt-1 m-0">
                            <div>
                                <b class="text-secondary">{{$tc('adventure.season', 0)}}:</b>
                                {{getExperience.seasons}}
                            </div>
                        </b-card-text>
                    </div>
                    <!-- carousel pictures -->
                    <div class="pt-1 pr-1 " style="min-width: 130px; max-width: 130px; height: 156px">
                        <!-- no picture -->
                        <b-img
                            draggable="false"
                            v-if="getExperience.pictures.length === 0"
                            fluid-grow
                            class="rounded-0"
                            src="@/assets/images/default.jpg"
                            style="height: 156px"
                        />
                        <!-- carousel pictures -->
                        <b-carousel
                            v-else
                            class="img-container"
                            background="#ababab"
                            v-model="slide"
                            :interval="0"
                            controls
                            fade
                            indicators
                            @sliding-start="onSlideStart"
                            @sliding-end="onSlideEnd"
                        >
                            <b-carousel-slide v-for="picture in getExperience.pictures">
                                <template #img>
                                    <b-img
                                        class="rounded-0"
                                        :src="picture.src"
                                        draggable="false"
                                        fluid-grow
                                        style="height: 156px"
                                    />
                                    <!--                                        :style="{'max-height': imageHeight}"-->
                                </template>
                            </b-carousel-slide>
                        </b-carousel>
                    </div>
                </div>
                <!-- buttons tab -->
                <div class="border mx-1">
                    <!-- buttons -->
                    <div class="d-flex flex-row flex-wrap bg-primary py-1 pl-1"
                         v-resize @resize="layoutResize($event, 'tabButtons', 'experience', 2)">
                        <!-- button story -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonEActive==='story' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonEActive='story'">
                            <b-icon
                                :icon="!__isNull(getExperience.story) ? 'b-icon-book-fill' : 'b-icon-book'"/>
                            {{$t('adventure.experience.tab.story')}}
                        </b-button>
                        <!-- button mission -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonEActive==='mission' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonEActive='mission'">
                            <b-icon
                                :icon="!__isNull(getExperience.mission) ? 'b-icon-trophy-fill' : 'b-icon-trophy'"/>
                            {{$t('adventure.experience.tab.mission')}}
                        </b-button>
                        <!-- button conditions -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonEActive==='conditions' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonEActive='conditions'">
                            <b-icon
                                :icon="!__isNull(getExperience.conditions) && !getExperience.conditions.isNull  ? 'b-icon-exclamation-triangle-fill' : 'b-icon-exclamation-triangle'"/>
                            {{$t('adventure.experience.tab.conditions')}}
                        </b-button>
                        <!-- button included -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonEActive==='included' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonEActive='included'">
                            <b-icon
                                :icon="!__isNull(getExperience.included) ? 'b-icon-gift-fill' : 'b-icon-gift'"/>
                            {{$t('adventure.experience.tab.included')}}
                        </b-button>
                        <!-- button opening hours -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonEActive==='openingHours' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonEActive='openingHours'">
                            <b-icon
                                :icon="!__isNull(getExperience.openingHours) ? 'b-icon-door-open-fill' : 'b-icon-door-open'"/>
                            {{$t('adventure.experience.tab.opening_hours')}}
                        </b-button>
                        <!-- button price list -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonEActive==='priceList' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonEActive='priceList'">
                            <b-icon
                                icon="b-icon-list"/>
                            {{$t('adventure.experience.tab.price_list')}}
                        </b-button>
                        <!-- language -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonEActive==='languages' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonEActive='languages'">
                            <b-icon
                                icon="b-icon-globe2"/>
                            {{$tc('adventure.tour_language', 1)}}
                        </b-button>
                        <!-- suitable for -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonEActive==='suitable' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonEActive='suitable'">
                            <b-icon
                                :icon="!__isNull(getExperience.suitable) ? 'b-icon-life-preserver' : 'b-icon-life-preserver'"/>
                            {{$t('adventure.suitable_for')}}
                        </b-button>
                        <!-- reviews -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonEActive==='reviews' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonEActive='reviews'">
                            <b-icon variant="danger"
                                    :icon="!__isNull(getDestination.reviews) ? 'b-icon-star-fill' : 'b-icon-star'"></b-icon>
                            {{$t('adventure.destination.tab.reviews')}}
                        </b-button>
                        <!-- notes -->
                        <b-button size="sm" style="font-size: smaller"
                                  :class="buttonEActive==='notes' ? 'button-tab-active' : 'button-tab'"
                                  @click="buttonEActive='notes'">
                            <b-icon variant="info"
                                    :icon="!__isNull(getDestination.notes) ? 'b-icon-file-earmark-text-fill' : 'b-icon-file-earmark-text'"></b-icon>
                            {{$t('adventure.destination.tab.notes')}}
                        </b-button>
                    </div>
                    <!-- bodies  -->
                    <!-- body story -->
                    <div v-show="buttonEActive==='story'"
                         class="overflow-auto p-1"
                         :style="{'height': getTabExperienceBodyHeight.px}"
                         v-html="getExperience.story"
                    />
                    <!-- body mission -->
                    <div v-show="buttonEActive==='mission'"
                         class="overflow-auto p-1"
                         :style="{'height': getTabExperienceBodyHeight.px}"
                         v-html="getExperience.mission"
                    />
                    <!-- body conditions -->
                    <div v-show="buttonEActive==='conditions'"
                         class="overflow-auto p-1"
                         :style="{'height': getTabExperienceBodyHeight.px}"
                    >
                        <!-- age limits -->
                        <div
                            v-if="getExperience.conditions.minAge !== null || getExperience.conditions.maxAge !== null">
                            <div class="font-weight-bold" style="font-size: larger">
                                {{$t('adventure.age_limits')}}
                            </div>
                            <!-- min age -->
                            <div v-if="getExperience.conditions.minAge !== null" class="mt-1">
                                <b>{{$t('adventure.min_age')}}: </b>
                                <span>{{getExperience.conditions.minAge}}</span>
                            </div>
                            <!-- max age -->
                            <div v-if="getExperience.conditions.maxAge !== null" class="mt-1">
                                <b>{{$t('adventure.max_age')}}: </b>
                                <span>{{getExperience.conditions.maxAge}}</span>
                            </div>
                        </div>
                        <!-- condition notes -->
                        <div v-if="getExperience.conditions.notes !== null">
                            <div class="mt-2 font-weight-bold" style="font-size: larger">
                                {{$t('adventure.other_condition')}}
                            </div>
                            <div class="mt-1">
                                <span class="p-0" v-html="getExperience.conditions.notes"></span>
                            </div>
                        </div>
                    </div>
                    <!-- body included -->
                    <div v-show="buttonEActive==='included'"
                         class="overflow-auto p-1"
                         :style="{'height': getTabExperienceBodyHeight.px}"
                         v-html="getExperience.included"
                    />
                    <!-- body opening hours -->
<!--                    <div v-show="buttonEActive==='openingHours'"
                         class="overflow-auto p-1"
                         :style="{'height': getTabExperienceBodyHeight.px}"
                    >
                        <div
                            v-for="(day, index) in getExperience.openingHours"
                            class="p-1"
                            :class="day.isClosed ? 'text-danger': ''"
                        >
                            &lt;!&ndash; day of week: name &ndash;&gt;
                            <div class="font-weight-bolder">
                                {{day.dayOfWeekName}}
                            </div>
                            &lt;!&ndash; only low season &ndash;&gt;
                            <div v-if="day.onlyLowSeason">
                                {{day.seasonsHours[0].hours[0].from}}-{{day.seasonsHours[0].hours[0].to}}
                            </div>
                            &lt;!&ndash; closed &ndash;&gt;
                            <div v-if="day.isClosed">
                                {{$t('adventure.closed')}}
                            </div>
                            &lt;!&ndash; seasons: (low season + seasons), hours &ndash;&gt;
                            <b-row class="mb-1 m-0 p-0" v-for="(seasons, index) in day.seasonsHours"
                                   v-if="!day.onlyLowSeason && !day.isClosed"
                                   align-v="baseline"
                            >
                                &lt;!&ndash; season (low season + seasons) &ndash;&gt;
                                <b-col class="mt-1 m-0 p-0" cols="4" v-if="seasons.hours.length > 0">
                                    <div style="font-weight: bolder">
                                        {{index === 0 ? seasons.season.title : seasons.season.label}}
                                    </div>
                                </b-col>
                                &lt;!&ndash; hours &ndash;&gt;
                                <b-col cols="3" v-for="hour in seasons.hours" v-if="seasons.hours.length > 0">
                                    {{hour.from}}-{{hour.to}}
                                </b-col>
                            </b-row>
                            &lt;!&ndash; hr line &ndash;&gt;
                            <hr class="mt-0 my-1" v-if="index < getExperience.openingHours.length-1">
                        </div>
                    </div>-->
                    <!-- price list -->
                    <div v-show="buttonEActive==='priceList'"
                         class="overflow-auto p-1"
                         :style="{'height': getTabExperienceBodyHeight.px}"
                    >
                        <b-table
                            v-if="getPriceListItems.length > 0"
                            :fields="getExperience.priceListColumns"
                            :items="getPriceListItems"
                            bordered head-row-variant="dark" hover outlined
                            responsive
                            striped
                            small
                            table-variant="light"
                            thead-class="text-center"
                            tbody-class="text-center"
                        />
                    </div>
                    <!-- language -->
                    <div v-show="buttonEActive==='languages'"
                         class="overflow-auto p-1"
                         :style="{'height': getTabExperienceBodyHeight.px}"
                    >
                        <div class="font-weight-bolder pb-1">{{$tc('adventure.tour_language', 1)}}:</div>
                        <div class="pl-1" v-for="languages in getExperience.languages">
                            - {{`${languages}`}}
                        </div>
                    </div>
                    <!-- suitable for -->
                    <div v-show="buttonEActive==='suitable'"
                         class="overflow-auto p-1"
                         :style="{'height': getTabExperienceBodyHeight.px}"
                    >
                        <div class="font-weight-bolder pb-1">
                            {{$t('adventure.suitable_for')}}:
                        </div>
                        <div class="pl-1" v-for="(suitable, index) in getExperience.suitable">
                            - {{`${suitable}`}}
                        </div>
                    </div>
                    <!-- body reviews -->
<!--                    <div v-show="buttonEActive==='reviews'"
                         class="overflow-auto p-1"
                         :style="{'height': getTabExperienceBodyHeight.px}"
                         v-html="getExperience.reviews"
                    />-->
                    <!-- body notes -->
                    <div v-show="buttonEActive==='notes'" class="overflow-auto p-1"
                         :style="{'height': getTabExperienceBodyHeight.px}"
                         v-html="getExperience.notes"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// @group ADVENTURE2
export default {
    name: "PopupDestination2",
    components: {},
    props: {
        data: {
            type: Object,
            default() {
                return {}
            }
        },
        /*
        * Hotlink for marking selected markers
        * <p>
        *   {"destinationId": "number", "experienceId": "number", "selected": "boolean", "popupOpen": "boolean"}
        *</p>
        */
        hotlink: {
            type: Object,
            default() {
                return {
                    destinationId: null,
                    experienceId: null,
                    selected: false,
                }
            }
        },
    },
    data() {
        return {
            // <-- layout -->
            layout: {
                main: {},
                destination: {},
                experience: {},
            },
            // <-- carousel -->
            carouselFirst: 0,
            carouselMax: 5,
            carouselDisabled: false,
            // <-- button tabs -->
            buttonDActive: 'description',
            buttonEActive: 'story',
            // <-- priceList -->
            priceListColumns: [
                {
                    key: 'price_unit_id',
                    label: this.$t('adventure.per_unit'),
                    sortable: false,
                    show: true,
                },
                {
                    key: 'age',
                    label: this.$t('adventure.age'),
                    sortable: false,
                    show: true,
                },
            ],
            // <-- carousel -->
            slide: 0,
        }
    },
    mounted() {
    },
    methods: {
        // <-- layout -->
        layoutResize(e, componentName, type, offset = 0) {
            let layout = this.__clone(this.layout);
            // <--  -->
            if (this.__isNull(layout[`${type}`])) {
                layout[`${type}`] = {};
            }
            this.$set(layout[`${type}`], componentName, {
                width: e.detail.width,
                height: e.detail.height + offset,
                // e: e.srcElement.clientHeight
            });
            // <--  -->
            this.$set(this, 'layout', layout);
        },
        // <-- multi carousel -->
        carouselPrevious() {
            if (this.carouselFirst === 0) {
                return;
            }
            this.carouselFirst--;
        },
        carouselNext() {
            this.carouselFirst++;
        },
        // <--  -->
        getPriceListSeasonByKey(content, key) {
            const dateOptions = {month: 'long', day: 'numeric'};
            // <--  -->
            return content.priceListSeasons.find((n) => {
                if (n.key === key) {
                    if (key !== 'default') {
                        n.label = `${this.__convertBaseDateToLocaleDateString_options(n.start, dateOptions)} - ${this.__convertBaseDateToLocaleDateString_options(n.end, dateOptions)}`;
                        return n;
                    }
                    n.title = this.$t("adventure.low_season");
                    return n;
                }
            });
        },
        getExperiencePictures(recordPictures) {
            let array = [];
            let caption;
            recordPictures.forEach(picture => {
                array.push(
                    {
                        caption: !this.__isNull(picture.locale) && !this.__isNull(picture.locale.name) ? picture.locale.name : picture.meta.name,
                        src: this.__getImageSrc(picture.image.src)
                    }
                );
            });
            // <--  -->
            return array;
        },
        // <-- priceList -->
        getPriceListColumns(content) {
            if (this.__isNull(content) || this.__isNull(content.priceList)) {
                return null;
            }
            // <--  -->
            let priceList = content.priceList;
            let columns = this.priceListColumns;
            // <--  -->
            let values = [];
            columns.forEach(column => {
                values.push({
                    key: column.key,
                    label: column.label,
                    formatter: column.formatter,
                    sortable: column.sortable,
                    show: column.show,
                });
            });
            // <-- add columns seasons -->
            let dateStart, dateEnd;
            let dateStartString, dateEndString;
            priceList.forEach(season => {
                Object.keys(season.seasonsPrices).forEach(key => {
                    // <-- default season -->
                    if (key === 'default') {
                        values.push({
                            key: key,
                            label: this.$t(`adventure.low_season`),
                        });
                    }
                    // <-- date start -->
                    else {
                        // <--  -->
                        values.push({
                            key: key,
                            label: this.getPriceListSeasonByKey(content, key).label,
                        });
                    }
                });
            });
            // <--  -->
            return values;
        },
        // <-- carousel -->
        onSlideStart(slide) {
            this.sliding = true;
        },
        onSlideEnd(slide) {
            this.sliding = false;
        },
    },
    computed: {
        // <-- layout -->
        getRightHeight() {
            let height = 0;
            // <--  -->
            if (this.__isNull(this.layout.main.right)) {
                return {
                    value: height,
                    px: height + 'px'
                }
            }
            // <--  -->
            height = this.layout.main.main.height;
            // <--  -->
            return {
                value: height,
                px: height + 'px'
            }
        },
        getTabDestinationBodyHeight() {
            let height = 0;
            if (this.__isNull(this.layout.main.right)) {
                return {
                    value: height,
                    px: height + 'px'
                }
            }
            // <--  -->
            height = this.layout.main.right.height;
            for (let key in this.layout.destination) {
                height -= this.layout.destination[key].height;
            }
            height -= 6;
            // <--  -->
            return {
                value: height,
                px: height + 'px'
            }
        },
        getTabExperienceBodyHeight() {
            let height = 0;
            if (this.__isNull(this.getRightHeight)) {
                return {
                    value: height,
                    px: height + 'px'
                }
            }
            // <--  -->
            height = this.getRightHeight.value;
            for (let key in this.layout.experience) {
                height -= this.layout.experience[key].height;
            }
            height -= 8;
            // <--  -->
            return {
                value: height,
                px: height + 'px'
            }
        },
        // <-- experiences -->
        getExperiences() {
            if (this.__isNull(this.data.experiences)) {
                return [];
            }
            // <--  -->
            let array = [];
            let name;
            this.data.experiences.forEach((experience, index) => {
                // <-- name -->
                name = !this.__isNull(experience.locale.name) ? experience.locale.name : experience.meta.name;
                array.push({
                    title: name,
                    record: experience
                });
            });
            // <--  -->
            return array;
        },
        getDestinationPictures() {
            if (this.__isNull(this.data.pictures)) {
                delete this.layout.destination.pictures;
                return null;
            }
            // <-- destinations pictures -->
            let pictures = this.__clone(this.data.pictures);
            pictures.forEach(picture => {
                picture.image.src = this.__getImageSrc(picture.image.src);
                picture.image.src_p = this.__getImageSrc(picture.image.src_p);
            });
            this.data.experiences.forEach(experience => {
                experience.pictures.every(picture => {
                    pictures.push({
                        image: {
                            src: this.__getImageSrc(picture.image.src),
                            src_p: this.__getImageSrc(picture.image.src_p)
                        }
                    });
                    return false;
                });
            });
            // <--  -->
            this.carouselDisabled = this.carouselFirst + this.carouselMax === pictures.length;
            // <--  -->
            return pictures.slice(this.carouselFirst, this.carouselFirst + this.carouselMax);
        },
        getDestination() {
            let object = {
                name: null,
                subtitle: null,
                languages: null,
                description: null,
                host: null,
                address: null,
                contact: null,
                reviews: null,
                notes: null,
                reloading: true,
            };
            if (this.__isNull(this.data)) {
                return object;
            }
            let data = this.data;
            // <-- subtitle -->
            let subtitle = !this.__isNull(data.locale.subtitle) ? data.locale.subtitle : data.meta.subtitle;
            // <-- name -->
            let name = !this.__isNull(data.locale.name) ? data.locale.name : data.meta.name;
            name += !this.__isNull(subtitle) ? ':' : '';
            // <-- address -->
            let address = null;
            if (!this.__isNull(data.address)) {
                address = '';
                address += this.__isNull(data.address.street) ? '' : data.address.street + '<br>';
                address += this.__isNull(data.address.postOffice) ? '' : data.address.postOffice + '<br>';
                address += this.__isNull(data.address.state) ? '' : data.address.state + '<br>';
                address += this.__isNull(data.address.region) ? '' : data.address.region + '<br>';
                address += this.__isNull(data.address.continent) ? '' : data.address.continent;
            }
            // <--  -->
            object.name = name;
            object.subtitle = subtitle;
            object.languages = this.__isNull(data.languagesTour) ? null : data.languagesTour.toString().replace(',', ', ');
            object.description = this.__isNull(data.locale) ? null : this.__removeParagrafFromText(data.locale.description);
            object.host = this.__isNull(data.locale) ? null : this.__removeParagrafFromText(data.locale.host);
            object.address = address;
            object.notes = this.__isNull(data.locale) ? null : this.__removeParagrafFromText(data.locale.notes);
            object.reloading = false;
            // <--  -->
            return object;
        },
        // <-- experience -->
        getExperience() {
            let object = {
                name: null,
                subtitle: null,
                languages: [],
                suitable: [],
                categoriesTypes: [],
                participantNumber: null,
                duration: null,
                seasons: null,
                story: null,
                mission: null,
                conditions: {
                    isNull: true,
                    minAge: null,
                    maxAge: null,
                    notes: null
                },
                included: null,
                openingHours: [],
                priceListColumns: null,
                reviews: null,
                pictures: [],
                notes: null,
                reloading: true,
            };
            if (this.__isNull(this.hotlink.experienceId) || this.__isNull(this.data) || this.__isNull(this.data.experiences)) {
                return object;
            }
            // <--  -->
            let data = this.data.experiences.find((n) => {
                if (n.id === this.hotlink.experienceId) {
                    return n;
                }
            });
            if(this.__isNull(data)){
                return object;
            }
            // <--  -->
            let categories = [];
            data.experienceTypes.forEach(value => {
                categories.push(value.categoryName);
            });
            categories = this.__uniqueArray(categories);
            categories = this.__sortArray(categories);
            // <-- types -->
            let categoriesTypes = [];
            let types = [];
            categories.forEach(category => {
                types = [];
                data.experienceTypes.forEach(record => {
                    if (category === record.categoryName) {
                        types.push(record.name);
                    }
                });
                categoriesTypes.push(
                    {
                        category: category,
                        types: types
                    }
                );
            });
            // <-- content -->
            let participantNumber = null;
            let duration = null;
            let seasons = null;
            let conditions = {
                isNull: true,
                minAge: null,
                maxAge: null,
                notes: null
            };
            if (!this.__isNull(data.content)) {
                // <-- participant number -->
                // <--  -->
                let min = data.content.participants_min;
                let max = data.content.participants_max;
                participantNumber = parseInt(min) === parseInt(max) ? max : min + '–' + max;
                // <-- duration -->
                let day = data.content.duration.days;
                let time = data.content.duration.time;
                // <-- remove leader 0 in time -->
                if (!this.__isNull(time) && time.substring(0, 1) === '0') {
                    time = time.substring(1);
                }
                // <--  -->
                duration = day > 0 ? day + ' ' + this.$tc('date_time.day', day === 1 ? 1 : 0).toLowerCase() : '';
                duration += time === null ? '' : ' ';
                duration += time === null ? '' : time + ' h';
                // <-- seasons -->
                let start = data.content.season.start;
                let end = data.content.season.end;
                // <--  -->
                if (this.__isNull(start) && this.__isNull(end)) {
                    seasons = this.$t('date_time.whole_year');
                } else {
                    seasons = (!this.__isNull(start) ? this.__convertBaseDateToLocaleDateString(start) : '') + '–' + (!this.__isNull(end) ? this.__convertBaseDateToLocaleDateString(end) : '');
                }
                // <-- conditions -->
                if (!this.__isNull(data.locale)) {
                    // <--  -->
                    let minAge = data.content.conditions.minAge;
                    let maxAge = data.content.conditions.maxAge;
                    let notes = this.__isNull(data.locale.conditionNotes) ? null : data.locale.conditionNotes;

                    let notesText = null;
                    if (!this.__isNull(notes)) {
                        // <-- remove html tags -->
                        notesText = notes.replace(/(<([^>]+)>)/ig, '');
                        // <-- remove spaces -->
                        notesText = notesText.replace(/\s/g, '');
                    }
                    // <--  -->
                    conditions = {
                        isNull: this.__isNull(minAge) && this.__isNull(maxAge) && this.__isNull(notesText),
                        minAge: minAge,
                        maxAge: maxAge,
                        notes: this.__isNull(notesText) ? null : this.__removeParagrafFromText(notes)
                    };
                }
            }
            // <-- opening hours -->
            let openingHoursObject = {
                tabTitle: null,
                openingHours: null,
            };
            if (!this.__isNull(data.content) && !this.__isNull(data.content.openingHours)) {
                // <-- opening hours -->
                let openingHours = [];
                data.content.openingHours.byDays.forEach(day => {
                    // <-- seasons -->
                    let seasonHours = [];
                    let hoursCount = 0;
                    Object.keys(day.seasonHours).forEach(key => {
                        // <-- hours -->
                        let hours = [];
                        day.seasonHours[key].forEach(hour => {
                            if (!this.__isNull(hour.from) && !this.__isNull(hour.to))
                                hours.push(hour);
                        });
                        hoursCount += hours.length;
                        seasonHours.push(
                            {
                                season: this.getPriceListSeasonByKey(data.content, key),
                                hours: hours
                            }
                        );
                    });
                    openingHours.push({
                        isClosed: hoursCount === 0,
                        onlyLowSeason: hoursCount === 1,
                        dayOfWeekName: day.dayOfWeekName,
                        seasonsHours: seasonHours,
                    });
                });
                // <--  -->
                openingHoursObject = {
                    tabTitle: data.content.type === 'sessions' ? this.$tc('adventure.experience.tab.session', 0) : this.$t('adventure.experience.tab.opening_hours'),
                    openingHours: openingHours,
                };
            }
            // <--  -->
            object.name = !this.__isNull(data.locale.name) ? data.locale.name : data.meta.name;
            object.subtitle = !this.__isNull(data.locale.subtitle) ? data.locale.subtitle : data.meta.subtitle;
            object.categoriesTypes = categoriesTypes;
            object.languages = this.__isNull(data.languagesTour) ? [] : data.languagesTour;
            object.suitable = this.__isNull(data.suitable) ? [] : data.suitable;
            object.participantNumber = participantNumber;
            object.duration = duration;
            object.seasons = seasons;
            object.story = this.__isNull(data.locale) || this.__isNull(data.locale.story) ? null : this.__removeParagrafFromText(data.locale.story);
            object.mission = this.__isNull(data.locale) || this.__isNull(data.locale.mission) ? null : this.__removeParagrafFromText(data.locale.mission);
            object.conditions = conditions;
            object.included = this.__isNull(data.locale) || this.__isNull(data.locale.included) ? null : data.locale.included;
            object.openingHours = openingHoursObject.openingHours;
            object.priceListColumns = this.getPriceListColumns(data.content);
            object.pictures = this.getExperiencePictures(data.pictures);
            object.notes = this.__isNull(data.locale) || this.__isNull(data.locale.notes) ? null : this.__removeParagrafFromText(data.locale.notes);
            // <--  -->
            return object;
        },
        getPriceListItems() {
            if (this.__isNull(this.hotlink.experienceId) || this.__isNull(this.data) || this.__isNull(this.data.experiences)) {
                return [];
            }
            // <--  -->
            let data = this.data.experiences.find((n) => {
                if (n.id === this.hotlink.experienceId) {
                    return n;
                }
            });
            if(this.__isNull(data)){
                return [];
            }
            // <--  -->
            let content = data.content;
            // <--  -->
            if (this.__isNull(content) || this.__isNull(content.priceList)) {
                return [];
            }
            // <--  -->
            let priceList = content.priceList;
            let values = [];
            // <--  -->
            priceList.forEach((value, index) => {
                if (value.price_unit !== null) {
                    // <-- minAge, maxAge -->
                    let ageMin = value.price_unit.minAge;
                    let ageMax = value.price_unit.maxAge;
                    let age = null;
                    if (ageMin !== null && ageMax === null) {
                        // age = `do ${minAge} let`
                        age = this.$t('adventure.age_under', {age_min: ageMin});
                    } else if (ageMin === null && ageMax !== null) {
                        age = this.$t('adventure.age_over', {age_max: ageMax});
                    } else if (ageMin !== null && ageMax !== null) {
                        age = this.$t('adventure.age_between', {age_min: ageMin, age_max: ageMax});
                    }
                    // <--  -->
                    values.push(
                        {
                            price_unit_id: value.price_unit.name,
                            age: age,
                        }
                    );
                    Object.keys(value.seasonsPrices).forEach(key => {
                        values[index][key] = `${this.__getDecimalLocale(value.seasonsPrices[key])} ${value.currency}`;
                    });
                }
            });
            // <--  -->
            return values;
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>
.body-images {
    /*background: #fd7e14;*/
    min-width: 30%;
    max-width: 30%;
    flex: 1 0 auto;
}

.image {
    /*width: 100% \9;*/
    object-fit: contain;
    object-position: center;
    vertical-align: center;
}


.main {
    /*flex: 1 0 auto;*/
    display: flex;
    flex-direction: row;
    height: inherit;
}

.left {
    background: whitesmoke;
    width: 25%;
    min-width: 25%;
    height: inherit;
    display: flex;
    flex-direction: column;
    flex-flow: flex-start;
}

.right {
    background: white;
    flex: 1 1 auto;
    height: inherit;

    display: flex;
    flex-direction: column;
    flex-flow: flex-start;
}

/*-- buttons --*/
.button-no-bg {
    background: none;
    border: none;
}

.button-no-bg:focus {
    background: none !important;
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
}

.button-tab {
    background: none;
    box-shadow: none !important;
    border: none;
}

.button-tab-active {
    /*background: none !important;*/
    /*outline: none !important;*/
    /*box-shadow: none !important;*/
    /*border: none !important;*/
}

</style>
